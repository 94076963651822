<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import {tokenAuth} from "@/helpers/authservice/token.service";

export default {
  name: "create-order",
  data() {
    return {
      title: "index",
      items: [
        {
          text: "newpage",
          href: "/",
        },
        {
          text: "index",
          active: true,
        },
      ],
      token: null
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.getTokenJwt();
  },
  methods: {
    async getTokenJwt() {
      const response = await tokenAuth();

      console.log(
          '===JWT TOKEN===',
          response
      );

      this.token = response
    }
  },
  computed: {
    tokenJwt() {
      return this.token;
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <b-row>
      <b-col lg="12 p-0">
        <div class="card">
          <iframe
              :src="`https://maqrosysweb.maqro.com.mx:56088/Iframe/pedido?JWT=${tokenJwt}`"
              ref="frame"
          >
          </iframe>
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>


<style lang="scss" scoped>

/* styles for desktop */
@media (min-width: 1024px) {
  .container-fluid {
    padding: 0 !important;
  }

  .page-content {
    padding: 0 !important;
  }

  .card {
    & > iframe {
      height: 70vw
    }
  }
}


/* styles for tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .card {
    height: 1024px;

    & > iframe {
      height: 100%
    }
  }
}

/* styles for mobile */
@media (max-width: 767px) {
  .card {
    height: 980px;

    & > iframe {
      height: 100%
    }
  }
}
</style>
